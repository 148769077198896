<template>
  <section id="gamePlan">
      <div class="gamePlan-cnt">
          <div class="gamePlan-title-cnt">
            <div class="gamePlan-title">Game Plan</div>
            <div class="line-divider"></div>
          </div>
          <div class="gp-holder">
              <div class="gpBox">
                  <div class="gp-title">Starting Field Position!</div>
                  <div class="gp-desc">Kickoff your event experience by planning and contracting your event with the Sales Team</div>
              </div>
              <div class="gpBox">
                  <div class="gp-title">To The 40!</div>
                  <div class="gp-desc">Huddle up with the Event Management Team to ensure the game plan is built for success</div>
              </div>
              <div class="gpBox">
                  <div class="gp-title">Midfield!</div>
                  <div class="gp-desc">Move the ball down the field with the Food and Beverage Team that will build out your menu</div>
              </div>
              <div class="gpBox">
                  <div class="gp-title">Redzone!</div>
                  <div class="gp-desc">Get into the Redzone and prepare to score by locking in all event details prior to the event</div>
              </div>
              <div class="gpBox">
                  <div class="gp-title">Touchdown!</div>
                  <div class="gp-desc">Score big with an impactful event at Allegiant Stadium</div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: "gamePlan"
};
</script>

<style lang="scss" scoped>
#gamePlan {
    width: 100vw;
    height: 100vh;
    background: url('~@/assets/images/VenueOverview/whoWeAre/bg-gamePlan.png') scroll no-repeat center right;
    background-size: cover;
    padding: 160px 0 160px 268px;
    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 268px;
        height: 100%;
        background: rgba(0,0,0,0.83);
    }
    .gamePlan-cnt {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        max-width: 460px;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 30px;
        margin: 0 30px 0 auto;
        @media screen and (min-width: 2000px){
            max-width: 800px;
        }
        .gamePlan-title-cnt {
            display: inline-flex;
            flex-wrap: wrap;
            align-self: flex-start;
            margin-bottom: 35px;
            .gamePlan-title {
                width: 100%;
                margin-bottom: 25px;
                font-size: 35px;
                font-family: 'ArtegraSans-Bold';
                line-height: 30px;
                letter-spacing: 2.19px;
                text-transform: uppercase;
                color: #FFFFFF;
            }
            .line-divider {
                position: relative;
                width: 100%;
                height: 2px;
                background: #FFFFFF;
                box-shadow: 0 0 9px 2px #FFFFFF;
            }
        }
        .gp-holder {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: 100%;
            .gpBox {
                display: flex;
                flex-wrap: wrap;
                width: 400px;
                margin: 14px 0;
                @media screen and (min-width: 2000px){
                    width: auto;
                }
                &:first-child {
                    margin: 0 0 14px 0;
                }
                &:last-child {
                    margin: 14px 0 0 0;
                }
                .gp-title {
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    font-family: 'ArtegraSans-Bold';
                    line-height: 21px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #FFFFFF;

                    @media screen and (min-width: 2000px){
                        font-size: 28px;
                        line-height: 37px;
                        margin-bottom: 10px;
                    }
                }
                .gp-desc {
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    font-family: 'ArtegraSans-Regular';
                    line-height: 21px;
                    letter-spacing: 1px;
                    color: #FFFFFF;
                    @media screen and (min-width: 2000px){
                        font-size: 26px;
                        line-height: 37px;
                    }
                }
            }
        }
    }
}
</style>